<div class="tile-grid mt-3 scrollbar-styled-larger" [ngClass]="{ scrollContainer: filteredRequests.length > 18 }" #scrollContainer (scroll)="showMoreConsults($event)">
  <div *ngIf="submitRequest" class="request fake" [routerLink]="['/request']">
    <span>
      <mat-icon>add</mat-icon>
      <label>{{ "COMPONENTS.DASHBOARD.NEW_ENERGY_CONSULT" | translate }}</label>
    </span>
  </div>
  <div class="tile" *ngFor="let i of showRequests; trackBy: trackByECId">
    <app-energy-consult
      (initRequests)="ngAfterContentInit()"
      [energyConsult]="i"
      [actions]="applicationService.session.user!.status === 'PendingDeleted' ? 2 : 0"
    ></app-energy-consult>
  </div>
</div>
