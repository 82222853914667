import { RequestState, RequestStates } from "../flow/request/RequestStates";
import { RoleName } from "../flow/session/Session";

export type ConsultviewTemplateRef =
  | "changeCoach"
  | "ckdTemplate"
  | "notesTemplate"
  | "scheduleVisit"
  | "rejectAndPutback"
  | "editConsultData"
  | "mailDataTimeline"
  | "downloadReport"
  | "fileUpload"
  | "fillReport"
  | "replacementClaim"
  | "claimTemplate";

type TemplateMapping = {
  [state in RequestStates]?: ConsultviewTemplateRef[];
};

export function canAccesTemplate(input: ConsultviewTemplateRef, RoleName: RoleName, RequestState: RequestState): boolean {
  const allowedTemplates = roleStateTemplateMapping[RoleName][RequestState.name];
  if (allowedTemplates && allowedTemplates.includes(input)) {
    return true;
  } else {
    return false;
  }
}

export const roleStateTemplateMapping: {
  [role in RoleName]: TemplateMapping;
} = {
  coordinator: {
    [RequestStates.NEW]: ["changeCoach", "ckdTemplate", "notesTemplate"],
    [RequestStates.CANCELED]: ["notesTemplate"],
    [RequestStates.PENDING_REJECT]: ["notesTemplate"],
    [RequestStates.PICKED]: ["changeCoach", "scheduleVisit", "rejectAndPutback", "editConsultData", "mailDataTimeline", "notesTemplate"],
    [RequestStates.DATE]: ["changeCoach", "scheduleVisit", "rejectAndPutback", "editConsultData", "mailDataTimeline", "notesTemplate"],
    [RequestStates.FILLED_IN]: ["downloadReport", "fileUpload", "mailDataTimeline", "notesTemplate"],
    [RequestStates.DONE]: ["downloadReport", "fileUpload", "mailDataTimeline", "notesTemplate"],
    [RequestStates.DISAPPROVED]: ["notesTemplate"],
    [RequestStates.PENDING_DELETED]: ["notesTemplate"],
  },
  coach: {
    [RequestStates.CANCELED]: ["notesTemplate"],
    [RequestStates.PENDING_REJECT]: ["notesTemplate"],
    [RequestStates.PICKED]: ["scheduleVisit", "rejectAndPutback", "editConsultData", "mailDataTimeline", "notesTemplate"],
    [RequestStates.DATE]: ["scheduleVisit", "fillReport", "rejectAndPutback", "editConsultData", "mailDataTimeline", "notesTemplate"],
    [RequestStates.FILLED_IN]: ["downloadReport", "replacementClaim", "fileUpload", "mailDataTimeline", "notesTemplate"],
    [RequestStates.DONE]: ["downloadReport", "replacementClaim", "fileUpload", "mailDataTimeline", "notesTemplate"],
  },
  guest: {},
  user: {},
  resident: {},
  retailer: {},
};
