import { User } from "./impl/User";
import { StorageService } from "../../../services/storage.service";
import { UserService } from "../../../services/user.service";
import { Specialty } from "../Questionnaire/Specialty";
import { Coordinator } from "./impl/Coordinator";
import { Coach } from "./impl/Coach";

export type RoleName = "guest" | "user" | "resident" | "retailer" | "coordinator" | "coach";

export interface Role {
  id: number;
  name: RoleName;
}

export class Session {
  public user?: User;
  public active = false;

  public get activeRole(): Role {
    return this.storageService.fetch<Role>("selectedRole", "sessionStorage") ?? { id: 0, name: "guest" };
  }

  public get authenticated() {
    return !!this.user;
  }

  public get hasSelectedRole() {
    return this.storageService.has("selectedRole", "sessionStorage");
  }

  public get userSpecialties(): Specialty[] {
    return this.storageService.fetch<Role[]>("userSpecialties", "sessionStorage") ?? [];
  }

  constructor(private readonly storageService: StorageService, private readonly userService: UserService) {}

  public async setActiveRole(value: Role) {
    await this.userService.changeRole(value);
    this.storageService.store<Role>("selectedRole", value, "sessionStorage");
    this.user = await this.userService.initialize(value);
  }

  public async setSessionUser(user: User | undefined): Promise<User> {
    if (user) {
      this.user = user;
    } else {
      this.user = await this.userService.initialize();
    }

    if (this.user instanceof Coordinator || this.user instanceof Coach) {
      this.storageService.store("userSpecialties", this.user.specialties, "sessionStorage");
    }

    return this.user;
  }
}
