import { Component, Input, EventEmitter, Output, OnChanges } from "@angular/core";
import { AreaAction } from "../../classes/flow/AreaActions/AreaAction";
import { AreaActionService } from "../../services/area-action.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { postalCodeValidator } from "../../validators/postalCode";
import { SnackbarService } from "../../services/snackbar.service";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-area-actions-form",
  templateUrl: "./area-actions-form.component.html",
  styleUrls: ["./area-actions-form.component.less"],
})
export class AreaActionsFormComponent implements OnChanges {
  @Input()
  private areaAction?: AreaAction;

  @Output()
  public cancel2: EventEmitter<void>;

  @Output()
  public save: EventEmitter<void>;

  public form: FormGroup<{
    zipCodeFrom: FormControl<string | null>;
    zipCodeTo: FormControl<string | null>;
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;
    area: FormControl<string | null>;
    municipality: FormControl<string | null>;
  }>;

  constructor(private areaActionService: AreaActionService, private snackbar: SnackbarService, private application: ApplicationService) {
    this.cancel2 = new EventEmitter();
    this.save = new EventEmitter();

    this.form = new FormGroup({
      zipCodeFrom: new FormControl<string | null>(null, [Validators.required, postalCodeValidator]),
      zipCodeTo: new FormControl<string | null>(null, [Validators.required, postalCodeValidator]),
      dateFrom: new FormControl<Date | null>(null, [Validators.required]),
      dateTo: new FormControl<Date | null>(null, [Validators.required]),
      area: new FormControl<string | null>(null, [Validators.required]),
      municipality: new FormControl<string | null>(null, [Validators.required]),
    });
  }

  ngOnChanges() {
    this.form.controls.zipCodeFrom.patchValue(this.areaAction?.zipCodeFrom ?? null);
    this.form.controls.zipCodeTo.patchValue(this.areaAction?.zipCodeTo ?? null);
    this.form.controls.dateFrom.patchValue(this.areaAction?.dateFrom ?? null);
    this.form.controls.dateTo.patchValue(this.areaAction?.dateTo ?? null);
    this.form.controls.area.patchValue(this.areaAction?.area ?? null);
    this.form.controls.municipality.patchValue(this.areaAction?.municipality ?? null);
  }

  cancelClickHandler() {
    this.cancel2.emit();
  }

  async submitHandler(event: Event) {
    event.preventDefault();
    if (!this.form.valid) return;
    const value = this.form.value;
    this.application.setLoading(true);
    try {
      if (this.areaAction) {
        const updatedAction = new AreaAction({ ...this.areaAction });
        updatedAction.zipCodeFrom = value.zipCodeFrom ?? this.areaAction.zipCodeFrom;
        updatedAction.zipCodeTo = value.zipCodeTo ?? this.areaAction.zipCodeTo;
        updatedAction.dateFrom = value.dateFrom ?? this.areaAction.dateFrom;
        updatedAction.dateTo = value.dateTo ?? this.areaAction.dateTo;
        updatedAction.area = value.area ?? this.areaAction.area;
        updatedAction.municipality = value.municipality ?? this.areaAction.municipality;
        // update
        await this.areaActionService.update(updatedAction);
      } else {
        // insert
        await this.areaActionService.add(value);
      }

      // Clean form
      this.form.reset();
      this.form.markAsPristine();
      this.form.markAsUntouched();

      // emit save, so overview knows to reinitialize
      this.save.emit();
    } catch (error) {
      this.snackbar.error();
    }
    this.application.setLoading(false);
  }
}
