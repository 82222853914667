import { Injectable, inject } from "@angular/core";
import { GraphQLService } from "./graphql.service";
import { ReportCategory, ReportCategoryGQL } from "../classes/flow/Questionnaire/ReportCategory";

@Injectable({
  providedIn: "root",
})
export class ReportCategoryService {
  private graphql: GraphQLService;

  constructor() {
    this.graphql = inject(GraphQLService);
  }

  public mapToObjects(items: ReportCategoryGQL[]): ReportCategory[] {
    return items.map((item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        active: item.active,
        changes: this.graphql.createChangesObject(item.changes),
      };
    });
  }

  public async getAllReportCategories(): Promise<ReportCategory[]> {
    const result = await this.graphql.query(`
        query {
          reportCategories {
            value {
              id
              name
              description
              active
              changes {
                create {
                  userId
                  time
                }
                lastChange {
                  userId
                  time
                }
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
              }
            }
            messages {
              message
            }
          }
        }
      `);

    return this.mapToObjects(result?.data?.["reportCategories"].value ?? []);
  }

  public async addReportCategory(reportCategory: ReportCategory): Promise<ReportCategory> {
    const res = await this.graphql.query(`
        mutation {
          addReportCategory(item: {name: "${reportCategory.name}", description: "${reportCategory.description}", active: ${reportCategory.active ? "true" : "false"}}) {
            value {
              id
              name
              description
              active
              changes {
                create {
                  userId
                  time
                }
                lastChange {
                  userId
                  time
                }
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
              }
            }
            messages {
              message
            }
          }
        }
      `);

    return this.mapToObjects([res?.data?.["addReportCategory"].value])[0];
  }

  public async updateReportCategory(reportCategory: ReportCategory): Promise<ReportCategory> {
    const res = await this.graphql.query(`
        mutation {
          updateReportCategory(input: {id: ${reportCategory.id}, set: {
          name: "${reportCategory.name}"
          description: "${reportCategory.description}"
          active: ${reportCategory.active ? "true" : "false"}
          changes: ${this.graphql.formatChangesObject(reportCategory)}
          }}) {
            value {
              id
              name
              description
              active
              changes {
                create {
                  userId
                  time
                }
                lastChange {
                  userId
                  time
                }
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
              }
            }
            messages {
              message
            }
          }
        }
      `);

    return this.mapToObjects([res?.data?.["updateReportCategory"].value])[0];
  }

  public async deleteReportCategory(reportCategoryID: number): Promise<void> {
    await this.graphql.query(`
        mutation {
          deleteReportCategory(id: ${reportCategoryID}) {
            value {
              id
            }
            messages {
              message
            }
          }
        }
      `);
  }

  public async addQuestionReportCategory(questionID: number, reportCategoryID: number): Promise<{ questionReportCategoryID: number } & ReportCategory> {
    const res = await this.graphql.query(`
      mutation {
        addQuestionReportCategory(item: {questionID: ${questionID}, reportCategoryID: ${reportCategoryID} }) {
          value {
            id
            reportCategory {
              id
              name
              description
              active
              changes {
                create {
                  userId
                  time
                }
                lastChange {
                  userId
                  time
                }
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
              }
            }
          }
          messages {
            message
          }
        }
      }
      `);

    return {
      ...this.mapToObjects([res?.data?.["addQuestionReportCategory"].value.reportCategory])[0],
      questionReportCategoryID: res?.data?.["addQuestionReportCategory"].value.id,
    };
  }

  //remove question link
  public async deleteQuestionReportCategory(questionReportCategoryID: number): Promise<void> {
    await this.graphql.query(`
      mutation {
        deleteQuestionReportCategory(id: ${questionReportCategoryID}) {
          value {
            id
          }
          messages {
            message
          }
        }
      }
      `);
  }
}
