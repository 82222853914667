<ng-container *appModule="'AREA_ACTIONS_MAP_LAYERS'">
  <div class="container mt-5" *ngIf="currentAreaAction">
    <div class="row">
      <div class="col-12">
        <div class="text-center">
          <h5 class="fw-bold">{{ "COMPONENTS.AREA_ACTIONS.HEADING" | translate }}</h5>
        </div>
        <div class="d-flex w-100 justify-content-around align-items-center mb-3">
          <button
            mat-raised-button
            color="accent"
            (click)="onLeftArrowClick()"
            [disabled]="areaActions.indexOf(currentAreaAction) === 0"
            [attr.aria-label]="'TABLES.GENERAL.PAGINATION.PREVIOUS_PAGE' | translate"
          >
            {{ "TABLES.GENERAL.PAGINATION.PREVIOUS_PAGE" | translate }}
          </button>
          <div class="d-flex flex-column text-center">
            <strong>{{ currentAreaAction.area }} - {{ currentAreaAction.municipality }}</strong>
            <span>{{ currentAreaAction ? currentAreaAction!.zipCodeFrom : "" }} - {{ currentAreaAction ? currentAreaAction!.zipCodeTo : "" }}</span>
            <span class="fst-italic text-muted">{{ currentAreaAction!.dateFrom | date : "dd-MM-YYYY" }} - {{ currentAreaAction!.dateTo | date : "dd-MM-YYYY" }}</span>
          </div>

          <button
            mat-raised-button
            color="accent"
            (click)="onRightArrowClick()"
            [disabled]="areaActions.indexOf(currentAreaAction) === areaActions.length - 1"
            [attr.aria-label]="'TABLES.GENERAL.PAGINATION.NEXT_PAGE' | translate"
          >
            {{ "TABLES.GENERAL.PAGINATION.NEXT_PAGE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="row">
  <div class="col-md">
    <google-map
      class="google-map"
      [center]="mapSettings.center"
      [zoom]="mapSettings.zoom"
      [ariaSetSize]=""
      width="100%"
      [options]="mapSettings.options"
      (zoomChanged)="zoomChangedEventHandler()"
      (centerChanged)="centerChangedEventHandler()"
    >
      <ng-container *ngIf="planningOverlay">
        <ng-container *ngFor="let set of sets">
          <ng-container *ngFor="let consult of set.consults; index as i">
            <map-marker
              *ngIf="consult.marker"
              [position]="consult.marker.position"
              [icon]="'assets/images/mapIcons/emptyRouteBlue.svg'"
              [label]="{ text: (i + 1).toString(), color: 'white', fontSize: '18px', fontWeight: 'bold',  }"
              [visible]="set.visible"
              [options]="{ zIndex: 100 }"
              (mapClick)="openInfo({ id: consult.id.toString(), state: consult.state, position: consult.marker.position, options: { zIndex: 100 } })"
            ></map-marker>
            <!-- z index should be done differently -->
          </ng-container>
          <map-polyline [path]="set.lines.path" [options]="{ visible: set.visible, icons: set.lines.icons, strokeColor: set.color }"></map-polyline>
        </ng-container>
      </ng-container>
      <ng-container>
        <map-marker
          *ngFor="let marker of markerPositions; let i = index"
          [position]="marker.position"
          [icon]="'assets/images/mapIcons/' + marker.state.name.toLowerCase() + '.svg'"
          (mapClick)="openInfo(marker)"
          [options]="marker.options"
        >
        </map-marker>
      </ng-container>
      <ng-container *appModule="'AREA_ACTIONS'">
        <map-marker
          *ngFor="let marker of areaActionMarkers; let i = index"
          [icon]="'assets/images/mapIcons/areaAction.svg'"
          [position]="marker.position"
          [options]="marker.options"
        >
        </map-marker>
      </ng-container>
      <ng-container *appModule="'AREA_ACTIONS_MAP_LAYERS'">
        <map-polygon *ngFor="let i of mapLayers" [paths]="i"></map-polygon>
      </ng-container>
    </google-map>
  </div>
  <div class="col-lg-4 position-relative overflow-hidden" *ngIf="selectedRequest">
    <div>
      <div class="closeButton position-absolute end-0 pe-3">
        <button mat-icon-button (click)="closeInfo()">
          <mat-icon class="mb-2">close</mat-icon>
        </button>
      </div>
      <app-energy-consult
        (initRequests)="ngAfterContentInit()"
        [energyConsult]="selectedRequest!"
        [mapView]="true"
        [actions]="applicationService.session.user!.status === 'PendingDeleted' ? 2 : 0"
      ></app-energy-consult>
    </div>
  </div>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <ng-container *ngTemplateOutlet="planningButtonTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="legendButtonTemplate"></ng-container>
    </div>
    <ng-container *ngIf="legendOverlay">
      <ng-container *ngTemplateOutlet="LegendOverlayTemplate"> </ng-container>
    </ng-container>
  </div>

  <div class="filterCardContainer" *ngIf="planningOverlay">
    <ng-container *ngFor="let set of sets; index as i">
      <mat-card class="filterCard">
        <div class="card-row">
          <div style="display: flex; flex-direction: column; justify-content: space-around">
            <div *ngFor="let coach of set.coaches">
              <b>
                {{ coach.fullName }}
              </b>
            </div>
            <div>{{ set.date | date : "dd-MM-YYYY" }}</div>
          </div>
          <div style="display: flex; flex-direction: column; justify-content: center">
            <button mat-icon-button [disabled]="false" (click)="removeSet(i)">
              <mat-icon>clear</mat-icon>
            </button>
            <button mat-icon-button (click)="set.visible = !set.visible">
              <mat-icon *ngIf="set.visible">visibility</mat-icon>
              <mat-icon *ngIf="!set.visible">visibility_off</mat-icon>
            </button>
          </div>
        </div>
        <ng-container *ngFor="let consult of set.consults; index as i">
          <div class="card-row">
            <p>
              <span>{{ i + 1 + ". " }}</span
              >{{ consult.location }}
            </p>
            <p>{{ consult.appointmentDate | date : "HH:mm" }}</p>
            <mat-icon
              *ngIf="consult.marker === undefined"
              [color]="'warn'"
              style="cursor: pointer"
              [matTooltip]="'COMPONENTS.DASHBOARD.PLANNING_LOCATION_NOT_FOUND' | translate"
              >warning</mat-icon
            >
          </div>
        </ng-container>
      </mat-card>
    </ng-container>
    <mat-card class="filterCard">
      <div class="card-row">
        <div style="display: flex; flex-direction: column">
          <mat-form-field>
            <mat-label>{{ "COMPONENTS.DASHBOARD.PLANNING_FORM_LABELS.COACH" | translate }}</mat-label>
            <mat-select [formControl]="form.controls.coaches" multiple>
              <mat-option *ngFor="let coach of coaches" [value]="coach">
                {{ coach.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "COMPONENTS.DASHBOARD.PLANNING_FORM_LABELS.DATE" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="form.controls.date" />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-around">
        <button mat-icon-button [disabled]="false" (click)="clearForm()">
          <mat-icon>clear</mat-icon>
        </button>
        <button mat-icon-button [disabled]="form.invalid || loading" (click)="addSet()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>

<ng-template #LegendOverlayTemplate>
  <div class="col-lg4 position-relative overflow-hidden">
    <div class="row">
      <div class="col-12">
        <div class="legenda">
          <ng-container *ngFor="let item of legendItems">
            <div *appIsRole="item.coordinatorOnly ? ['coordinator'] : ['coach', 'coordinator']" class="legenda_item">
              <img [alt]="'COMPONENTS.TILE.STATUSES.' + item.stateName | translate" [src]="'assets/images/mapIcons/' + item.iconFile + '.svg'" />
              <span>{{ "COMPONENTS.TILE.STATUSES." + item.stateName | translate }}</span>
            </div>
          </ng-container>
          <div *appModule="'AREA_ACTIONS'">
            <img [alt]="'MENU.ITEMS.AREA_ACTIONS' | translate" [src]="'assets/images/mapIcons/areaAction.svg'" />
            <span>{{ "MENU.ITEMS.AREA_ACTIONS" | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #legendButtonTemplate>
  <span
    matTooltip="{{ 'COMPONENTS.EVERYWHERE.MAPVIEWHINTS.LEGEND_HINT' | translate }}"
    (click)="toggleLegendOverlay()"
    [style.color]="legendOverlay ? 'var(--primary-button)' : 'gray'"
    [style.cursor]="'pointer'"
    [style.width]="'auto'"
    >{{ "COMPONENTS.EVERYWHERE.MAPVIEWHINTS.LEGEND" | translate }}</span
  >
</ng-template>
<ng-template #planningButtonTemplate>
  <button
    mat-icon-button
    (click)="togglePlanningOverlay()"
    matTooltip="{{ 'COMPONENTS.EVERYWHERE.MAPVIEWHINTS.PLANNING_HINT' | translate }}"
    [style.color]="planningOverlay ? 'var(--primary-button)' : 'gray'"
  >
    <mat-icon>directions</mat-icon>
  </button>
</ng-template>
